import React from "react";
import { useRouter } from "next/router";
import { CommonAppContext } from "@bay1/data";
import { IntegrationEnvironment } from "@bay1/sdk/generated/graphql";

const Landing = (): JSX.Element => {
  const router = useRouter();
  const { activeOrganization, localStore } = React.useContext(CommonAppContext);
  const referrer = localStore?.getRedirectURL() ?? "";

  React.useEffect(() => {
    if (referrer.length > 0) {
      localStore?.setRedirectURL("");
      // eslint-disable-next-line fp/no-mutation
      window.location.href = decodeURIComponent(referrer);
    } else if (
      activeOrganization?.profile.environment === IntegrationEnvironment.TEST
    ) {
      void router.push(`/organizations/${activeOrganization.id}/get-started`);
    } else {
      void router.push(`/organizations/${activeOrganization?.id}/home`);
    }
  }, [activeOrganization, router, referrer, localStore]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <></>;
};

// eslint-disable-next-line import/no-unused-modules
export default Landing;
